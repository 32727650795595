@import "./base/tailwind.css";
@import "./base/print.css";

@import "./components/autocomplete.css";
@import "./components/buttons.css";
@import "./components/containers.css";
@import "./components/forms.css";
@import "./components/messages.css";
@import "./components/title.css";

@import "./pages/courier-tour.css";
a {
  @apply underline;
}
